import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './contactOptionsComponent.css';

const ContactWay = ({ selectedContactWay, setSelectedContactWay }) => {
    const { t, i18n } = useTranslation();
    const contactOptions = [
        {
            text: "EMAIL",
            value: "1"
        },
        {
            text: "FORM_NAME_PHONE",
            value: "2"
        },
        {
            text: "BOTH",
            value: "3"
        }
    ]
    return (
        <div className='contactWayMainContainer'>
            <p>{t('CONTACT_WAY')}</p>
            <div className='contactWayOptions'>
                {contactOptions.map((option) => (
                    <p className='contactWayOption' onClick={() => setSelectedContactWay(option.value)}>
                        <input className='contactWayInput' type="radio" name="" id="" checked={selectedContactWay === option.value} />
                        <label className='contactWayLabel' htmlFor="">{t(option.text)}</label>
                    </p>
                ))}
            </div>
        </div>
    );
};

export default ContactWay;
