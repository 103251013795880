const optionsCountries = [
    { "country": "Alemania", "code": "+49" },
    { "country": "Arabia Saudita", "code": "+966" },
    { "country": "Argentina", "code": "+54" },
    { "country": "Australia", "code": "+61" },
    { "country": "Bahrein", "code": "+973" },
    { "country": "Bangladés", "code": "+880" },
    { "country": "Bélgica", "code": "+32" },
    { "country": "Bolivia", "code": "+591" },
    { "country": "Brasil", "code": "+55" },
    { "country": "Camerún", "code": "+237" },
    { "country": "Canadá", "code": "+1" },
    { "country": "Catar", "code": "+974" },
    { "country": "Chile", "code": "+56" },
    { "country": "China", "code": "+86" },
    { "country": "Colombia", "code": "+57" },
    { "country": "Corea del Sur", "code": "+82" },
    { "country": "Costa de Marfil", "code": "+225" },
    { "country": "Costa Rica", "code": "+506" },
    { "country": "Cuba", "code": "+53" },
    { "country": "Ecuador", "code": "+593" },
    { "country": "Egipto", "code": "+20" },
    { "country": "Emiratos Árabes Unidos", "code": "+971" },
    { "country": "España", "code": "+34" },
    { "country": "Estados Unidos", "code": "+1" },
    { "country": "Filipinas", "code": "+63" },
    { "country": "Fiji", "code": "+679" },
    { "country": "Francia", "code": "+33" },
    { "country": "Ghana", "code": "+233" },
    { "country": "Grecia", "code": "+30" },
    { "country": "India", "code": "+91" },
    { "country": "Indonesia", "code": "+62" },
    { "country": "Irán", "code": "+98" },
    { "country": "Iraq", "code": "+964" },
    { "country": "Israel", "code": "+972" },
    { "country": "Italia", "code": "+39" },
    { "country": "Japón", "code": "+81" },
    { "country": "Jordania", "code": "+962" },
    { "country": "Kenia", "code": "+254" },
    { "country": "Kuwait", "code": "+965" },
    { "country": "Líbano", "code": "+961" },
    { "country": "Libia", "code": "+218" },
    { "country": "Malasia", "code": "+60" },
    { "country": "Marruecos", "code": "+212" },
    { "country": "México", "code": "+52" },
    { "country": "Nigeria", "code": "+234" },
    { "country": "Noruega", "code": "+47" },
    { "country": "Nueva Zelanda", "code": "+64" },
    { "country": "Omán", "code": "+968" },
    { "country": "Pakistán", "code": "+92" },
    { "country": "Panamá", "code": "+507" },
    { "country": "Papúa Nueva Guinea", "code": "+675" },
    { "country": "Países Bajos", "code": "+31" },
    { "country": "Perú", "code": "+51" },
    { "country": "Polonia", "code": "+48" },
    { "country": "Portugal", "code": "+351" },
    { "country": "Reino Unido", "code": "+44" },
    { "country": "Rumania", "code": "+40" },
    { "country": "Rusia", "code": "+7" },
    { "country": "Senegal", "code": "+221" },
    { "country": "Singapur", "code": "+65" },
    { "country": "Sudáfrica", "code": "+27" },
    { "country": "Sudán", "code": "+249" },
    { "country": "Suecia", "code": "+46" },
    { "country": "Tailandia", "code": "+66" },
    { "country": "Tanzania", "code": "+255" },
    { "country": "Tonga", "code": "+676" },
    { "country": "Turquía", "code": "+90" },
    { "country": "Túnez", "code": "+216" },
    { "country": "Ucrania", "code": "+380" },
    { "country": "Uganda", "code": "+256" },
    { "country": "Uruguay", "code": "+598" },
    { "country": "Venezuela", "code": "+58" },
    { "country": "Vietnam", "code": "+84" },
    { "country": "Yemen", "code": "+967" }
];

export default optionsCountries;