import React from 'react';
import './utilities/i18n'; // Importa la configuración de i18next 
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/App';
import Services from './components/services/content/services_content';
import ContractUs from './pages/Contract_us';
import WorkWithUs from './pages/work-us';
import FindTopTalent from './pages/find-top-talent';
import TechServicesMain from './pages/techServices/techServices';
import Contractors from './pages/consulting/contractors';
import ConsultingComponent from './pages/consulting/consulting/consulting';
import ContractingComponent from './pages/consulting/contractor/contractor';
import Outsourcing from './pages/consulting/outsourcing/outsourcing';
import ApplyConsultingComponent from './pages/consulting/consulting/apply/apply';
import ApplyContractorComponent from './pages/consulting/contractor/apply/apply';
import ApplyOutsourcingComponent from './pages/consulting/outsourcing/apply/apply';
import TechServicesForm from './pages/techServices/techservicesForm';
import VMAComponent from './pages/VMA/VMA_main';
import SpecialistComponent from './pages/VMA/specialist/specialist';
import HireVMAComponent from './pages/VMA/Hire/hire';
import Recruitment from './pages/recruitment/recruitment';
import SpecialistHireForm from './pages/VMA/specialist/Form/specialistForm';
import GetHireForm from './pages/VMA/Hire/Form/getHiredform';
import Investment from './pages/investment/investment';
import FinancialServices from './pages/financial-services/financial-services';
import About_Us from './pages/about-us/about-us';
import Events from './pages/Events/Events';
import ERP_MoreServices from './pages/more-services/erp/erp';
import CRM_MoreServices from './pages/more-services/crm/crm';
import ECommerce_MoreServices from './pages/more-services/e-commerce/ecommerce';
import Blog from './pages/blog/main/blog';
import Article_blog from './pages/blog/article/article';
import ScrollToTop from './utilities/scrollTop';





function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/investment" element={<Investment />} />
        <Route path="/financials" element={<FinancialServices />} />
        <Route path="/about-us" element={<About_Us />} />
        <Route path="/events" element={<Events />} />
        <Route path="/contractus" element={<ContractUs />} />
        <Route path="/techservices">
          <Route index element={<TechServicesMain />} />
          <Route path=":service" element={<Services />} />
          <Route path="adquire" element={<TechServicesForm />} />
        </Route>
        <Route path="/work-with-us">
          <Route index element={<WorkWithUs />} />
          {/* <Route index element={<BenefitsEmployees />} /> */}
        </Route>
        <Route path="/find-top-talent">
          <Route index element={<FindTopTalent />} />
          {/* <Route index element={<BenefitsEmployees />} /> */}
        </Route>
        <Route path="/contractors">
          <Route index element={<Contractors />} />
          <Route path="consulting">
            <Route index element={<Outsourcing />} />
            <Route path="apply" element={<ApplyConsultingComponent />} />
          </Route>
          <Route path="work">
            <Route index element={<ConsultingComponent />} />
            <Route path="apply" element={<ApplyContractorComponent />} />
          </Route>
          <Route path="outsourcing">
            <Route index element={<ContractingComponent />} />
            <Route path="apply" element={<ApplyOutsourcingComponent />} />
          </Route>
        </Route>
        <Route path="/vma">
          <Route index element={<VMAComponent />} />
          <Route path="specialist">
            <Route index element={<SpecialistComponent />} />
            <Route path="hire" element={<SpecialistHireForm />} />
          </Route>
          <Route path="gethired">
            <Route index element={<HireVMAComponent />} />
            <Route path="hire" element={<GetHireForm />} />
          </Route>
        </Route>
        <Route path="/blog">
          <Route index element={<Blog />} />
          <Route path="article/:id" element={<Article_blog />} />
        </Route>
        <Route path="/moreservices">
          <Route path="erp">
            <Route index element={<ERP_MoreServices />} />
          </Route>
          <Route path="crm">
            <Route index element={<CRM_MoreServices />} />
          </Route>
          <Route path="ecommerce">
            <Route index element={<ECommerce_MoreServices />} />
          </Route>
        </Route>
        <Route path="/recruitment" element={<Recruitment />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
