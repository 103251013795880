import React, { useState, useEffect, useRef } from "react";
import "./about-us.css";
import { useTranslation } from 'react-i18next';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import MainTitleComponent from "../../components/mainTitleComponent/mainTitleComponent";
import Contact_us from "../../components/contact_us";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import TextAreaComponent from "../../components/textAreaComponent";
import InputComponent from "../../components/inputComponent";
import FetchSuccessComponent from "../../components/fetchComponent/fetchComponent";
import useDropdown from "../../hooks/searchDropdownHook";
import { filterByFieldOption, filterByCountryObject } from "../../utilities/filterfunctionsDropdowns";
import optionsCountries from "../../utilities/countries_code";



const About_Us = () => {
    const { t } = useTranslation();

    const companyInfoArray = [
        {
            title: t("INNOVATIVE_SOLUTIONS"),
            description: t("INNOVATIVE_DESCRIPTION"),
            image: "/img/glowing-filament-ignites-ideas-innovative-solutions-generated-by-ai 1.png" // replace with actual image path or URL
        },
        {
            title: t("EXPERIENCED_TEAM"),
            description: t("EXPERIENCED_DESCRIPTION"),
            image: "/img/DreamShaper_v7_programmer_creating_a_new_app_from_a_big_compan_0 1.png" // replace with actual image path or URL
        },
        {
            title: t("CUSTOMER_CENTRIC"),
            description: t("CUSTOMER_DESCRIPTION"),
            image: "/img/DreamShaper_v7_Holographic_lady_showing_companies_dashboards_a_1 2.png" // replace with actual image path or URL
        },
        {
            title: t("PROVEN_TRACK_RECORD"),
            description: t("PROVEN_TRACK_RECORD_DESCRIPTION"),
            image: "/img/bulls-eye-hit-successful-dart-aim-perfect-accuracy-generated-by-ai 1.jpg" // replace with actual image path or URL
        }
    ];

    const teamMembers = [
        {
            img: '/img/foto perfil lorena 1.png',
            title: 'Lorena Cordoba',
            text: 'CEO',
            linkedin: 'https://www.linkedin.com/in/lorena-c%C3%B3rdoba-68a6a122a/'
        },
        {
            img: '/img/foto perfil camilo.png',
            title: 'Camilo Cruz',
            text: 'UI/UX Designer',
            linkedin: 'https://www.linkedin.com/in/camilo-cruz-q/'
        },
        {
            img: '/img/foto perfil josue.png',
            title: 'Josue Salamanca',
            text: 'Software Architect',
            linkedin: 'https://www.linkedin.com/in/josue-salamanca-3587131ba/'
        },
        {
            img: '/img/foto perfil julian.png',
            title: 'Julian Huertas',
            text: 'Software Architect',
            linkedin: 'https://www.linkedin.com/in/julian-steven-huertas-henao-875b8a22b/'
        },
        {
            img: '/img/foto perfil lucia.png',
            title: 'Lucia Barón',
            text: 'Corporate Assistant',
            linkedin: 'https://www.linkedin.com/in/luc%C3%ADa-b-256b861b7/'
        },
        {
            img: '/img/foto perfil camila.png',
            title: 'Camila Montealegre',
            text: 'Business Developer',
        },
        {
            img: '/img/foto perfil martin.png',
            title: 'Martin Sanchez',
            text: 'Software Developer',
            linkedin: 'https://www.linkedin.com/in/mart%C3%ADn-s%C3%A1nchez-77077b24a/'
        },
        {
            img: '/img/foto perfil alejandro.png',
            title: 'Alejandro Olarte',
            text: 'Software Developer',
            linkedin: 'https://www.linkedin.com/in/jose-alejandro-olarte-torres/'
        },

    ];


    const {
        isOpen: openDropdownCountryCode,
        setIsOpen: setOpenDropdownCountryCode,
        searchTerm: searchTermCountryCode,
        filteredArray: filteredCountryCode,
        inputRef: elementoRef,
        handleSearchChange: handleSearchChangeCountryCode,
        selectedItem: selectedCountryCode,
        setSelectedItem: setSelectedCountryCode
    } = useDropdown(optionsCountries, filterByCountryObject);

    function TargetComponent({ img, text, title, className }) {
        return (
            <div className={`targetComponentContainer ${className}`}>
                <img src={img} alt="" />
                <div className="textContainer">
                    <p className={`mainTitle`}>{title}</p>
                    <p className={`mainText`}>{text}</p>
                </div>
            </div>
        )
    }


    // FORM LOGIC 
    const navigate = useNavigate();
    const handleRedirect = (route) => {
        navigate(route + "/", { replace: true });
    };

    const [openDropdownCountry, setOpenDropdownCountry] = useState(false)
    const inputRef = useRef();
    const [skillsArray, setSkillsArray] = useState([])
    const [openDropdowns, setOpenDropdowns] = useState({});
    const initialData = {
        firstName: "",
        phoneNumberCode: "",
        phoneNumber: "",
        candidateEmail: "",
        additionalInformation: "",
        acceptTerms: false,
        policies: false
    }
    const [loading, setLoading] = useState(false)
    const [errors, setErros] = useState({})
    const [form, setForm] = useState(initialData)

    const handleDropdown = (set) => {
        set((prev) => !prev);
    }


    const handleChange = (event) => {
        const { name, value, checked } = event.target
        if (name === 'acceptTerms') {
            setForm({ ...form, [name]: checked })
        } else {
            setForm({ ...form, [name]: value })
        }

    }

    const handleSelectOption = (option, keyOption, set) => {
        if (keyOption == 'skills') {

            setSkillsArray([...skillsArray, option]);
            setForm({
                ...form,
                [keyOption]: [...skillsArray, option].join(",")
            });

        } else if (keyOption === "phoneNumberCode") {
            setSelectedCountryCode(option);
            setForm({ ...form, [keyOption]: option })
        } else {
            setForm({ ...form, [keyOption]: option })
        }
        set((prev) => !prev);
    }




    const onValidate = (form) => {

        let errors = {};


        if (!form.firstName.trim()) {
            errors.firstName = t('NAME_REQUIRED');
        }

        if (!form.phoneNumberCode.trim()) {
            errors.phoneNumberCode = t('PHONE_CODE_REQUIRED');
        }

        if (!form.phoneNumber.trim()) {
            errors.phoneNumber = t('PHONE_NUMBER_REQUIRED');
        }

        if (!form.candidateEmail.trim()) {
            errors.candidateEmail = t('EMAIL_REQUIRED');
        }

        if (!form.additionalInformation.trim()) {
            errors.additionalInformation = t('ADDITIONAL_INFO_REQUIRED');
        }

        if (!form.policies) {
            errors.policies = t('POLICIES_REQUIRED');
        }

        return errors
    }



    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');

    const handleFileChange = async (event) => {
        setSelectedFile(event.target.files[0]);
        const base64File = await convertToBase64(event.target.files[0]);
        setForm({
            ...form, ["candidateResume"]: {
                "content-type": event.target.files[0].type,
                content: base64File,
            }
        })
    };

    const onChooseFile = () => {
        inputRef.current.click();
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = (error) => reject(error);
        });
    };


    const handleClickOutside = (event) => {
        if (elementoRef.current && !elementoRef.current.contains(event.target)) {
            setOpenDropdownCountryCode(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const form2 = useRef();

    onValidate(form)

    const [isFetchSuccessful, setIsFetchSuccessful] = useState(false);

    const sendEmail = async (e) => {
        e.preventDefault();
        const validationErrors = onValidate(form);

        setErros(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await fetch('https://prod-129.westus.logic.azure.com:443/workflows/bf38140d6bef4e538dea2b9cc9f26ac0/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=NCbb73tnyfsJSnpo9_BnoLKGPQVBuhatCgYb28Tq1s4', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(form),
                });
                if (response.ok) {
                    setIsFetchSuccessful(true);
                } else {
                    setIsFetchSuccessful(false);
                }

            } catch (error) {
                setUploadStatus('Error uploading file.');
            }
        }
    };

    const notifySucces = () => toast.success('Form send correctly', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
    });

    const notifyError = () => toast.error('Form does not send correctly', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
    });

    const [dropdowns, setDropdowns] = useState([{ id: 1, value: '' }]);

    const handleSelectOptionDropdown = (option, dropdownId) => {
        setDropdowns((prev) =>
            prev.map((dropdown) =>
                dropdown.id === dropdownId ? { ...dropdown, value: option } : dropdown
            )
        );

        setOpenDropdowns((prev) => ({ ...prev, [dropdownId]: false }));

        setForm((prev) => ({
            ...prev,
            service: [...prev.service, option],
        }));
    };


    const addDropdown = () => {
        setDropdowns((prev) => [...prev, { id: prev.length + 1, value: '' }]);
    };


    // Nuevas variables de estado y opciones
    const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);


    const countryOptions = [
        { Option: 'US', Value: 'United States' },
        { Option: 'CA', Value: 'Canada' },
        // Añade más opciones según sea necesario
    ];

    const departmentOptions = [
        { Option: 'NY', Value: 'New York' },
        { Option: 'CA', Value: 'California' },
        // Añade más opciones según sea necesario
    ];

    // Refs para cerrar dropdowns al hacer clic fuera de ellos
    const countryDropdownRef = useRef(null);


    const [dropdownState, setDropdownState] = useState({ id: 1, selectedValue: '' });
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef2 = useRef(null);

    const handleOptionSelect = (option) => {
        setDropdownState({ ...dropdownState, selectedValue: option });
        setIsDropdownOpen(false);
    };

    return (
        <>
            <Header />
            <main className="aboutMaincontainer">
                <MainTitleComponent
                    title={t('ABOUT_US')}
                />

                <section className="image-text-section">
                    <div className="image-container">
                        <img src="/img/young-adult-programmer-working-indoors-night-using-computer-generated-by-artificial-intelligence.jpg" alt="About Us" className="about-us-image" />
                    </div>
                    <div className="overlay-text">
                        <p>{t('GET_TO_KNOW')}</p>
                    </div>
                    <p className="tetxBlack">{t('ABOUT_US_TEXT')}</p>
                </section>

                <MainTitleComponent title={t('MEET_OUR_TEAM')} />

                <section className="about positionSection">
                    <div className="roofsContainer">
                        {teamMembers.map((data, index) => (
                            <div className="teamCardContainer" key={index}>
                                <div className="imageContainer">
                                    <img src={data.img} alt={data.title} />
                                </div>
                                <div className="textContainer">
                                    <h2 className="mainTitle">{data.title}</h2>
                                    <p className="mainText">{data.text}</p>
                                    {data.linkedin && (
                                        <a href={data.linkedin} target="_blank" rel="noopener noreferrer" className="logo">
                                            <img src="/img/Linkedin3.svg" />
                                        </a>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </section>



                <MainTitleComponent title={t('WHY_CHOOSE_US')} />

                <section className="about positionSection">
                    <div className="roofsContainer">
                        {companyInfoArray.map((data, index) => (
                            <TargetComponent
                                key={index}
                                img={data.image}
                                text={data.description}
                                title={data.title}
                                className={index % 2 === 1 ? 'second' : ''}
                            />
                        ))}
                    </div>
                </section>
                {/*                 <p className="textH4">{t('HAND_IN_HAND')}</p> */}

                <section className="contactUsForm">
                    <div className="workWithUsFormMainContainer">
                        <h2 className="titleH2">{t('CONTACT_US')}</h2>
                        <div className="contact-us-forms">
                            <form ref={form2} onSubmit={sendEmail}>
                                <p className="form-text">{t('FORM_TEXT')}</p>
                                <div className="nameContainer">
                                    <div className="errorContainer">
                                        <InputComponent
                                            className={`input-width`}
                                            name={'firstName'}
                                            value={form.firstName}
                                            handleChange={handleChange}
                                            label={t('YOUR_NAME')}
                                        />
                                        {errors.firstName && <div className="alert alert-danger">{errors.firstName}</div>}
                                    </div>
                                </div>
                                <div className="phoneEmailContainer">
                                    <div>
                                        <p>{t('FORM_LABEL_PHONE')}</p>
                                        <div className="phoneContactContainer phoneCode">
                                            <div className="phoneContactDropdown phoneCode" ref={elementoRef}>
                                                <div className="phoneContactInputContainer">
                                                    <input
                                                        type="text"
                                                        className={`inputNumber ${openDropdownCountryCode && "active"}`}
                                                        onClick={() => handleDropdown(setOpenDropdownCountryCode)}
                                                        onChange={handleSearchChangeCountryCode}
                                                        name="phoneNumberCode"
                                                        value={selectedCountryCode ? selectedCountryCode : searchTermCountryCode}
                                                        autoComplete="off"
                                                    />
                                                    <img src={'/img/arrowDownDropdown.svg'} alt="" />
                                                </div>
                                                {openDropdownCountryCode && (
                                                    <div className="phoneContactOptions">
                                                        <div className="optionsContainer">
                                                            {filteredCountryCode.map((option) => (
                                                                <p onClick={() => handleSelectOption(option.code, "phoneNumberCode", setOpenDropdownCountryCode)} key={option.country}>
                                                                    {option.country}
                                                                </p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="errorContainer">
                                                <InputComponent
                                                    className={`input-width`}
                                                    name={'phoneNumber'}
                                                    type={'number'}
                                                    handleChange={handleChange}
                                                    value={form.phoneNumber}
                                                />
                                            </div>
                                        </div>
                                        {errors.phoneNumber && <div className="alert alert-danger">{errors.phoneNumber}</div>}
                                        {errors.phoneNumberCode && <div className="alert alert-danger">{errors.phoneNumberCode}</div>}
                                    </div>
                                    <div className="errorContainer email">
                                        <InputComponent
                                            className={`input-width`}
                                            label={t('YOUR_EMAIL')}
                                            name={'candidateEmail'}
                                            type={'email'}
                                            value={form.candidateEmail}
                                            handleChange={handleChange}
                                        />
                                        {errors.candidateEmail && <div className="alert alert-danger">{errors.candidateEmail}</div>}
                                    </div>
                                </div>
                                <p>{t('YOUR_MESSAGE')}</p>
                                <div className="errorContainer">
                                    <TextAreaComponent
                                        className={`input-width textArea`}
                                        name={'additionalInformation'}
                                        handleChange={handleChange}
                                        value={form.additionalInformation}
                                    />
                                    {errors.additionalInformation && <div className="alert alert-danger">{errors.additionalInformation}</div>}
                                </div>
                                <div className="termsAndConditionsContainer">
                                    <input type="checkbox" name="policies" onChange={handleChange} />
                                    <p className="termsAndConditionsText">{t('FORM_POLICY')} <a href="/PoliticaDeTratamientoDeDatosPersonales.pdf">{t('FORM_POLICY_LINK')}</a></p>
                                </div>
                                {errors.policies && <div className="alert alert-danger">{errors.policies}</div>}
                                <button className="button">{loading ? t('FORM_BUTTON_LOADING') : t('FORM_BUTTON')}</button>
                                <FetchSuccessComponent isFetchSuccessful={isFetchSuccessful} setIsFetchSuccessful={setIsFetchSuccessful} url={"/"} />
                            </form>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default About_Us;
