import React from 'react';
import './fetchComponent.css'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FetchSuccessComponent = ({ isFetchSuccessful, setIsFetchSuccessful, url }) => {
    const { t } = useTranslation();

    return (
        <>
            {isFetchSuccessful && (
                <>
                    <div className="blurrBackground"></div>
                    <div className="isFetchSuccessful">
                        <div className="succesIcon">
                            <img src="/img/succesIcon.svg" alt="Success Icon" />
                        </div>
                        <p className="titleH4Bold">{t('REQUEST_SUCCESS')}</p>
                        <p className="textBlack">{t('EXPERT_REACH')}</p>
                        <button className="button" onClick={() => setIsFetchSuccessful(false)}>
                            <Link to={url} className={`link`}>
                                {t('CONTINUE_EXPLORING')}
                            </Link>
                        </button>
                    </div>
                </>
            )}
        </>
    );
};

export default FetchSuccessComponent;
