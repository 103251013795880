import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import './contractors.css'
import TargetComponent from "../../components/targetsComponent/targetComponent";
import BannerComponent from "../../components/bannerComponent/bannerComponent";
import ButtonLinkComponent from "../../components/buttonLink/buttonLink";
const Contractors = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleRedirect = (route) => {
        navigate(route, { replace: true });
    };

    const targetComponentsData = [
        {
            img: '/img/openart-image_q4ZmL_6X_1715785012435_raw 1.jpg',
            text: t('CONTRACTORS_FLEXIBILITY')
        },
        {
            img: '/img/openart-image_3Jcty8BN_1715785552469_raw 1.jpg',
            text: t('CONTRACTORS_EXPERTISE')
        },
        {
            img: '/img/caucasian-businessman-holding-piggy-bank-smiling-confidently-generated-by-ai 1.jpg',
            text: t('CONTRACTORS_MULTIPLE_SERVICES')
        }
    ];

    const servicesData = [
        {
            type: 'consulting',
            translateTitle: t('COMPANIES_CONSULTING_SERVICES'),
            buttonLabel: t('HOME_SERVICES_BUTTON'),
            className: 'consulting',
            url: '/contractors/consulting',
        },
        {
            type: 'outsourcing',
            translateTitle: t('OUTSOURCING_SERVICES'),
            className: 'outsourcing',
            buttonLabel: t('HOME_SERVICES_BUTTON'),
            url: '/contractors/outsourcing',
        },
        // Añade más objetos según sea necesario
    ];
    return (
        <>
            <Header />
            <main className="contractorsMainContainer">
                <h1 className="title">{t('CONNECT_BEST_WORKHAND')}</h1>
                <p className="textMainContractor">{t('EMPOWER_BUSINESS')}</p>
                <ButtonLinkComponent
                    translateButton={'BOOK_APPOINTMENT_BUTTON'}
                    url={"https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/"}
                    blank={true}
                />
                <div className="services-container" id="services-container">
                    <div className="services-portfolio">
                        {servicesData.map((service, index) => (
                            <BannerComponent
                                key={index}
                                translateTitle={service.translateTitle}
                                className={`${service.className} `}
                                classNameSide={`l`}
                                translateButton={`EXPLORE`}
                                url={service.url}
                            />
                        ))}
                    </div>
                </div>
                <div className="roofsContainer">
                    {targetComponentsData.map((data, index) => (
                        <TargetComponent
                            key={index}
                            img={data.img}
                            text={data.text}
                        />
                    ))}
                </div>
                <ButtonLinkComponent
                    translateButton={'BOOK_APPOINTMENT_BUTTON'}
                    url={"https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/"}
                    blank={true}
                />
            </main>
            <Footer />
        </>
    );
};

export default Contractors;
