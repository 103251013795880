import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import "./apply.css";
import { useTranslation } from 'react-i18next';
import Header from "../../../../components/header/header";
import Footer from "../../../../components/footer/footer";
import { ToastContainer, toast } from 'react-toastify';
import InputComponent from "../../../../components/inputComponent";
import TextAreaComponent from "../../../../components/textAreaComponent";
import axios from "axios";
import FetchSuccessComponent from "../../../../components/fetchComponent/fetchComponent";
import useDropdown from "../../../../hooks/searchDropdownHook";
import optionsCountries from "../../../../utilities/countries_code";
import { filterByCountryObject } from "../../../../utilities/filterfunctionsDropdowns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Select } from "@mobiscroll/react";
import ContactWay from "../../../../components/contactOptionsComponent/contactOptionsComponent";

const ApplyOutsourcingComponent = () => {
  const { t } = useTranslation();
  const [openDropdownCountry, setOpenDropdownCountry] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [skillsArray, setSkillsArray] = useState([]);
  const [openDropdownVacancies, setOpenDropdownVacancies] = useState(false);
  const [servicesData, setServicesData] = useState([
    { "Option": "SOFTWARE_DEVELOPER" },
    { "Option": "CYBERSECURITY_EXPERT" },
    { "Option": "FULLSTACK_DEVELOPER" },
    { "Option": "DATABASE_EXPERT" },
    { "Option": "UX_UI_DESIGNER" }
  ]);
  const inputRefPosition = useRef();
  const inputRefTime = useRef();

  const initialData = {
    firstName: "",
    lastName: "",
    phoneNumberCode: "",
    phoneNumber: "",
    candidateEmail: "",
    company: "",
    positions: [],
    timeNeedExpert: "",
    additionalInformation: "",
    policies: false
  };

  const {
    isOpen: openDropdownCountryCode,
    setIsOpen: setOpenDropdownCountryCode,
    searchTerm: searchTermCountryCode,
    filteredArray: filteredCountryCode,
    inputRef: elementoRef,
    handleSearchChange: handleSearchChangeCountryCode,
    selectedItem: selectedCountryCode,
    setSelectedItem: setSelectedCountryCode
  } = useDropdown(optionsCountries, filterByCountryObject);

  const removeDropdown = (dropdownId) => {
    setPositionDropdowns((prev) => prev.filter((dropdown) => dropdown.id !== dropdownId));
    setForm((prev) => ({
      ...prev,
      positions: prev.positions.filter((_, index) => index !== dropdownId - 1),
    }));
  };

  const [loading, setLoading] = useState(false)
  const [errors, setErros] = useState({})
  // const [selectedPhonePrefix, setSelectedPhonePrefix] = useState(initialData.phone_prefix)
  const [form, setForm] = useState(initialData)

  const expertTime = [
    { "Option": "SHORT_TERM" },
    { "Option": "MID_TERM" },
    { "Option": "LONG_TERM" }
  ];


  const handleDropdown = (set) => {
    set((prev) => !prev);
  }

  const handleChange = (event) => {
    const { name, value, checked } = event.target
    if (name != "candidateEmail" && name !== "additionalInformation") {
      if (name === 'phoneNumber' || name === 'previousExperience') {
        if (value < 0) {
          return;
        }
      } else {
        if (!/^[^\d]*$/.test(value)) {
          return;
        }
      }
    }
    if (name === 'policies') {
      setForm({ ...form, [name]: checked })
    } else {
      setForm({ ...form, [name]: value })
    }

  }

  const handleSelectOption = (option, keyOption, set) => {
    setSelectedCountryCode(option);
    setForm({ ...form, [keyOption]: option })
    set((prev) => !prev);
  }


  const [openPositionDropdowns, setOpenPositionDropdowns] = useState({});
  const [positionDropdowns, setPositionDropdowns] = useState([{ id: 1, value: '' }]);
  const [openTimeDropdowns, setOpenTimeDropdowns] = useState({});
  const [timeDropdowns, setTimeDropdowns] = useState([{ id: 1, value: '' }]);

  const handleSelectOptionPosition = (option, dropdownId, customOption = false) => {
    setPositionDropdowns((prev) =>
      prev.map((dropdown) =>
        dropdown.id === dropdownId ? { ...dropdown, value: option, customOption, isEditing: customOption } : dropdown
      )
    );

    setOpenPositionDropdowns((prev) => ({ ...prev, [dropdownId]: false }));

    if (!customOption) {
      setForm((prev) => ({
        ...prev,
        positions: [...prev.positions, option],
      }));
    }
  };

  const handleCustomOptionChange = (value, dropdownId) => {
    setPositionDropdowns((prev) =>
      prev.map((dropdown) =>
        dropdown.id === dropdownId ? { ...dropdown, value: value } : dropdown
      )
    );

    setForm((prev) => ({
      ...prev,
      positions: [...prev.positions.filter((pos, index) => index < dropdownId - 1), value],
    }));
  };

  const handleEditSave = (dropdownId, isEditing) => {
    setPositionDropdowns((prev) =>
      prev.map((dropdown) =>
        dropdown.id === dropdownId ? { ...dropdown, isEditing: !isEditing } : dropdown
      )
    );
  };

  const addPositionDropdown = () => {
    setPositionDropdowns((prev) => [...prev, { id: prev.length + 1, value: '', customOption: false, isEditing: false }]);
  };

  const handleSelectOptionTime = (option, dropdownId) => {
    setTimeDropdowns((prev) =>
      prev.map((dropdown) =>
        dropdown.id === dropdownId ? { ...dropdown, value: option } : dropdown
      )
    );

    setOpenTimeDropdowns((prev) => ({ ...prev, [dropdownId]: false }));

    setForm((prev) => ({
      ...prev,
      timeNeedExpert: option,
    }));
  };

  const [selectedContactWay, setSelectedContactWay] = useState("");

  const onValidate = (form) => {
    let errors = {};

    if (!form.firstName.trim()) {
      errors.firstName = t('NAME_REQUIRED');
    }

    if (!form.lastName.trim()) {
      errors.lastName = t('LAST_NAME_REQUIRED');
    }

    if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (!form.phoneNumberCode.trim())) {
      errors.phoneNumberCode = t('PHONE_CODE_REQUIRED');
    }

    if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (!form.phoneNumber.trim())) {
      errors.phoneNumber = t('PHONE_NUMBER_REQUIRED');
    }

    if (((selectedContactWay === "1") || (selectedContactWay === "3")) && (!form.candidateEmail.trim())) {
      errors.candidateEmail = t('EMAIL_REQUIRED');
    }

    if (!selectedContactWay) {
      errors.contactWay = t("CONTACT_WAY_VALIDATION")
    }

    if (!form.company.trim()) {
      errors.company = t('COMPANY_REQUIRED');
    }

    if (form.positions.length === 0) {
      errors.position = t('POSITIONS_REQUIRED');
    }

    if (form.timeNeedExpert.length === 0) {
      errors.timeNeedExpert = t('TIME_NEED_EXPERT_REQUIRED');
    }

    if (!form.additionalInformation.trim()) {
      errors.additionalInformation = t('ADDITIONAL_INFO_REQUIRED');
    }

    if (form.policies === false) {
      errors.policies = t('POLICIES_REQUIRED');
    }

    if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (form.phoneNumber <= 0)) {
      errors.phoneNumber = t('PHONE_NUMBER_ZERO')
    }

    const isEditing = positionDropdowns.some((dropdown) => dropdown.isEditing);

    if (isEditing) {
      errors.position = t('SAVE_POSITIONS_REQUIRED');
    }

    const hasEmptyDropdown = positionDropdowns.some((dropdown) => !dropdown.value.trim());

    if (hasEmptyDropdown) {
      errors.position = t('POSITIONS_REQUIRED');
    }

    return errors;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (elementoRef.current && !elementoRef.current.contains(event.target) &&
        // !event.target.classList.contains('inputNumber') &&
        // !event.target.classList.contains('inputNumber2') &&
        !event.target.classList.contains('arrowDownInput')
      ) {
        setOpenDropdownCountryCode(false);
      }
      if (inputRefPosition.current && !inputRefPosition.current.contains(event.target) &&
        // !event.target.classList.contains('inputNumber') &&
        // !event.target.classList.contains('inputNumber2') &&
        !event.target.classList.contains('arrowDownInput')
      ) {
        setOpenPositionDropdowns(false);
      }
      if (inputRefTime.current && !inputRefTime.current.contains(event.target) &&
        // !event.target.classList.contains('inputNumber') &&
        // !event.target.classList.contains('inputNumber2') &&
        !event.target.classList.contains('arrowDownInput')
      ) {
        setOpenTimeDropdowns(false);
      }

    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const form2 = useRef();

  onValidate(form)

  const [isFetchSuccessful, setIsFetchSuccessful] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    const validationErrors = onValidate(form);
    setErros(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {

        const response = await fetch('https://prod-56.westus.logic.azure.com:443/workflows/b3d297fd257443a5a59cc1677ea38dca/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=eswIgRatP76YLa-XJboZItzKtbZIHU5jZ_7gT-EEb-M', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(form),
        });

        if (response.ok) {
          setIsFetchSuccessful(true); // Cambia el estado a true si la respuesta es exitosa
        } else {
          setIsFetchSuccessful(false); // Cambia el estado a false si la respuesta no es exitosa
        }


      } catch (error) {
        setIsFetchSuccessful(false);
      }
    }
  };

  const [dropdowns, setDropdowns] = useState([{ id: 1, value: '' }]);

  return (
    <>
      <Header />
      <main className="formMaincontainer">
        <div className="mainImgOutSorcingApply"></div>
        <main className="workWithUsFormMainContainer requestExpert">
          <div className="contact-us-forms">
            <form ref={form2} onSubmit={sendEmail}>
              <h2 className="form-title">{t('FORM_TITLE_COLLABORATE')}</h2>
              <div className="nameContainer">
                <div className="errorContainer">
                  <InputComponent
                    className={`input-width`}
                    name={'firstName'}
                    value={form.firstName}
                    handleChange={handleChange}
                    label={t('FORM_FIRST_NAME')}
                  />
                  {errors.firstName && <div className="alert alert-danger">{errors.firstName}</div>}
                </div>
                <div className="errorContainer">
                  <InputComponent
                    className={`input-width`}
                    name={'lastName'}
                    value={form.lastName}
                    handleChange={handleChange}
                    label={t('FORM_LAST_NAME')}
                  />
                  {errors.lastName && <div className="alert alert-danger">{errors.lastName}</div>}
                </div>
              </div>
              <ContactWay
                selectedContactWay={selectedContactWay}
                setSelectedContactWay={setSelectedContactWay}
              />
              <div className="phoneEmailContainer">
                {((selectedContactWay === "2") || (selectedContactWay === "3")) && (<div className="phoneContactContainerMain">
                  <p>{t('FORM_PHONE_NUMBER')}</p>
                  <div className="phoneContactContainer phoneCode">
                    <div className="phoneContactDropdown phoneCode" ref={elementoRef}>
                      <div className="phoneContactInputContainer">
                        <input
                          type="text"
                          className={`inputNumber ${openDropdownCountryCode && "active"}`}
                          onClick={() => handleDropdown(setOpenDropdownCountryCode)}
                          onChange={handleSearchChangeCountryCode}
                          name="phoneNumberCode"
                          value={selectedCountryCode ? selectedCountryCode : searchTermCountryCode}
                          autoComplete="off"
                        />
                        <img src={'/img/arrowDownDropdown.svg'} alt="" />
                      </div>
                      {openDropdownCountryCode && (
                        <div className="phoneContactOptions">
                          <div className="optionsContainer">
                            {filteredCountryCode.map((option) => (
                              <p onClick={() => handleSelectOption(option.code, "phoneNumberCode", setOpenDropdownCountryCode)} key={option.country}>
                                {option.country}
                              </p>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="errorContainer">
                      <InputComponent
                        className={`input-width`}
                        name={'phoneNumber'}
                        type={'number'}
                        handleChange={handleChange}
                        value={form.phoneNumber}
                      />
                    </div>
                  </div>
                  {errors.phoneNumber && <div className="alert alert-danger">{errors.phoneNumber}</div>}
                  {errors.phoneNumberCode && <div className="alert alert-danger">{errors.phoneNumberCode}</div>}
                </div>)}
                {((selectedContactWay === "1") || (selectedContactWay === "3")) && (<div className="errorContainer email">
                  <InputComponent
                    className={`input-width`}
                    label={t('FORM_EMAIL')}
                    name={'candidateEmail'}
                    type={'email'}
                    value={form.candidateEmail}
                    handleChange={handleChange}
                  />
                  {errors.candidateEmail && <div className="alert alert-danger">{errors.candidateEmail}</div>}
                </div>)}
              </div>
              {errors.contactWay && <div className="alert alert-danger">{errors.contactWay}</div>}
              <div className="errorContainer">
                <InputComponent
                  className={`input-width`}
                  name={'company'}
                  label={t('FORM_COMPANY_NAME')}
                  value={form.company}
                  handleChange={handleChange}
                />
                {errors.company && <div className="alert alert-danger">{errors.company}</div>}
              </div>
              <div className={`phoneContactContainer services  ${(positionDropdowns.length > 1 || positionDropdowns[positionDropdowns.length - 1].value) && "edit"}`}>
                <div className="dropdownsContainer">
                  <p>{t('FORM_POSITION_LOOKING_FOR')}</p>
                  {positionDropdowns.map((dropdown) => (
                    <div key={dropdown.id} className="phoneContactDropdown" ref={inputRefPosition}>
                      <div className={`phoneContactInputContainer ${dropdown.isEditing ? "edit" : "saved"}`}>
                        <div className="servicesDropdownContainer">
                          <input
                            type="text"
                            className={`inputNumber2 ${dropdown.value && 'active'}`}
                            onClick={() =>
                              dropdown.customOption || setOpenPositionDropdowns((prev) => ({
                                ...prev,
                                [dropdown.id]: !prev[dropdown.id],
                              }))
                            }
                            placeholder={dropdown.customOption ? t('WRITE_YOUR_DESIRED_POSITION') : t('WORK_US_VACANCY')}
                            name={`vacancy-${dropdown.id}`}
                            readOnly={!dropdown.customOption}
                            disabled={!!dropdown.value && !dropdown.isEditing}
                            value={dropdown.customOption ? dropdown.value : t(dropdown.value)}
                            onChange={(e) => handleCustomOptionChange(e.target.value, dropdown.id)}
                          />
                          {!dropdown.customOption && (
                            <img src={'/img/arrowDownDropdown.svg'} alt="" />
                          )}
                        </div>
                        {(positionDropdowns.length > 1 || positionDropdowns[positionDropdowns.length - 1].value) && (
                          <p
                            className="editButton"
                            onClick={() =>
                              handleEditSave(dropdown.id, dropdown.isEditing)
                            }
                          >
                            {dropdown.isEditing ? t('Save') : t('EDIT')}
                          </p>
                        )}
                        {positionDropdowns.length > 1 && (
                          <div className="removeButton">
                            <FontAwesomeIcon icon={faTrash} onClick={() => removeDropdown(dropdown.id)} />
                          </div>
                        )}
                      </div>
                      {!dropdown.customOption && openPositionDropdowns[dropdown.id] && (
                        <div className="phoneContactOptions">
                          <div className="optionsContainer">
                            {servicesData.map((option) => {
                              const isOptionSelected = positionDropdowns.some((d) => d.value === t(option.Option));
                              if (!isOptionSelected) {
                                return (
                                  <p
                                    className="dropdownOption"
                                    onClick={() => handleSelectOptionPosition(t(option.Option), dropdown.id, false)}
                                    key={option.Option}
                                  >
                                    {t(option.Option)}
                                  </p>
                                );
                              }
                              return null;
                            })}
                            <p onClick={() => handleSelectOptionPosition('', dropdown.id, true)} className="dropdownOption">{t('OTHER')}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  {errors.position && <div className="alert alert-danger">{errors.position}</div>}
                  {positionDropdowns[positionDropdowns.length - 1].value && (
                    <button className="buttonAddDropdown" onClick={() => addPositionDropdown('new')}>
                      + {t('ADD_ANOTHER_POSITION')}
                    </button>
                  )}
                </div>
              </div>



              <div className="phoneContactContainer services" >
                <div className="phoneContactDropdown" ref={inputRefTime}>
                  <p>{t('FORM_TIME_NEED_EXPERT')}</p>
                  {timeDropdowns.map((dropdown) => (
                    <div key={dropdown.id} className="phoneContactDropdown">
                      <div className="phoneContactInputContainer">
                        <input
                          type="text"
                          className={`inputNumber2 inputNumber2Time ${dropdown.value && 'active'}`}
                          onClick={() =>
                            setOpenTimeDropdowns((prev) => ({
                              ...prev,
                              [dropdown.id]: !prev[dropdown.id],
                            }))
                          }
                          placeholder={t('FORM_SELECT_OPTION')}
                          name={`timeNeedExpert-${dropdown.id}`}
                          readOnly
                          value={dropdown.value}
                        />
                        <img src={'/img/arrowDownDropdown.svg'} alt="" />

                      </div>
                      {openTimeDropdowns[dropdown.id] && (
                        <div className="phoneContactOptions">
                          <div className="optionsContainer">
                            {expertTime.map((option) => {
                              return (
                                <p
                                  className="dropdownOption"
                                  onClick={() =>
                                    handleSelectOptionTime(t(option.Option), dropdown.id)
                                  }
                                  key={option.Option}
                                >
                                  {t(option.Option)}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {errors.timeNeedExpert && <div className="alert alert-danger">{errors.timeNeedExpert}</div>}

              <p>{t('WORK_US_RESUME_MESSAGE')}</p>
              <div className="errorContainer">
                <TextAreaComponent
                  className={`input-width textArea`}
                  name={'additionalInformation'}
                  handleChange={handleChange}
                  value={form.additionalInformation}
                />
                {errors.additionalInformation && <div className="alert alert-danger">{errors.additionalInformation}</div>}
              </div>
              <div className="termsAndConditionsContainer">
                <input type="checkbox" name="policies" onChange={handleChange} />
                <p className="termsAndConditionsText">{t('FORM_POLICY')} <a href="/PoliticaDeTratamientoDeDatosPersonales.pdf">{t('FORM_POLICY_LINK')}</a></p>
              </div>
              {errors.policies && <div className="alert alert-danger">{errors.policies}</div>}
              <button className="button">{loading ? t('FORM_BUTTON_LOADING') : t('FORM_BUTTON')}</button>
              <FetchSuccessComponent isFetchSuccessful={isFetchSuccessful} setIsFetchSuccessful={setIsFetchSuccessful} url={'/contractors'} />
            </form>
          </div>
        </main>
      </main>

      <Footer />
    </>
  );
};

export default ApplyOutsourcingComponent;
