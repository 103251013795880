import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonLinkComponent from "../buttonLink/buttonLink";
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher'; // Ajusta la ruta según tu estructura de carpetas

const Header = (props) => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState("")

  useEffect(() => {
    if (location.pathname === '/') {
      setCurrentLocation('home')
    }
    if (location.pathname === '/techservices' || location.pathname === '/techservices/ia/' || location.pathname === '/techservices/rpa/' || location.pathname === '/techservices/cloud/' || location.pathname === '/techservices/bi/' || location.pathname === '/techservices/web-app-dev/') {
      setCurrentLocation('services')
    }
  }, [location]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false)
  const [showServicesHeader, setShowServicesHeader] = useState(false)
  const [showTechServicesHeader, setShowTechServicesHeader] = useState(false)

  const handleRedirect = (route) => {
    navigate(route);
  };

  const handleOpenServicesNavBar = () => {
    setShowServicesHeader(!showServicesHeader);
  };
  const handleOpenTechServicesNavBar = () => {
    setShowTechServicesHeader(!showTechServicesHeader);
  };

  const elementoRef = useRef(null);

  useEffect(() => {
    // Función para manejar los clics en toda la página
    const handleClickOutside = (event) => {
      if (elementoRef.current && !elementoRef.current.contains(event.target) &&
        !event.target.classList.contains('menuHeaderPhone') &&
        !event.target.classList.contains('servicesContainer') &&
        !event.target.classList.contains('secondary-header-button') &&
        !event.target.classList.contains('serviceElement') &&
        !event.target.classList.contains('techServicesContainer') &&
        !event.target.classList.contains('imgHeaderservices')) {
        // Si el clic ocurre fuera del elemento deseado, cambiar el estado
        setShowMenu(false);
        setShowServicesHeader(false)
        setShowTechServicesHeader(false)
      }
    };

    // Agregar event listener al montar el componente
    document.addEventListener('click', handleClickOutside);

    // Limpiar event listener al desmontar el componente
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const solutions = [
    // { name: t('TECH_SOLUTIONS'), link: "/techservices" },
    { name: t('CONSULTING_SOLUTIONS'), link: '/contractors' },
    { name: t('VIRTUAL_MEDICAL_ASSISTANT'), link: '/vma' },
    { name: t('RECRUITMENT_SERVICES'), link: '/recruitment' },
    { name: t('INVESTMENT_SOLUTIONS'), link: '/investment' },
    { name: t('ERP'), link: '/moreservices/erp' },
    { name: t('CRM'), link: '/moreservices/crm' },
    { name: t('E-Commerce B2B'), link: '/moreservices/ecommerce' },
    { name: t('Blog'), link: '/blog' },
    { name: t('HEADER_EVENTS'), link: '/events' },
  ];
  const solutionsTech = [
    // { name: t('TECH_SOLUTIONS'), link: "/techservices" },
    { name: t('HOME_SERVICE_IA_TITLE_1'), name2: t('HOME_SERVICE_IA_TITLE_2'), link: '/techservices/ia' },
    { name: t('HOME_SERVICE_RPA_TITLE_1'), name2: t('HOME_SERVICE_RPA_TITLE_2'), link: '/techservices/rpa' },
    { name: t('HOME_SERVICE_CLOUD_TITLE'), link: '/techservices/cloud' },
    { name: t('HOME_SERVICE_BI_TITLE_1'), name2: t('HOME_SERVICE_BI_TITLE_2'), link: '/techservices/bi' },
    { name: t('HOME_SERVICE_WEB_DEV_TITLE_1'), name2: t('HOME_SERVICE_WEB_DEV_TITLE_2'), link: '/techservices/web-app-dev' },
  ];
  return (
    <>
      <div className={`blur-background ${showMenu && "active"}`}></div>
      <div className="header-main-container">
        <div className="header-container">
          <div className="header-home-container">
            <img className="logo-5ig" src={`/img/Logo5ig.png`} onClick={() => handleRedirect('/')} alt="Logo_5ig"></img>
          </div>
          <div className="header-contact-container">
            <button className={`secondary-header-button ${currentLocation === 'home' && 'activeWindow'}`} onClick={() => handleRedirect('/')}>{t('HOME_BUTTON')}</button>
            <div className="servicesContainer">
              <div className="serviceTextContainer" onClick={() => handleOpenServicesNavBar()}>
                <button className={`secondary-header-button ${showServicesHeader && "activeWindow"} ${currentLocation === 'services' && 'activeWindow'}`}>{t('HEADER_SERVICES')}</button>
                <img className="imgHeaderservices" src={`${showServicesHeader ? "/img/arrowUpHeader.svg" : "/img/arrowDownHeader.svg"}`} alt="" />
              </div>
              <div className={`ServicesNavbar ${showServicesHeader && "active"}`}>
                <p className={`techServicesContainer ${showTechServicesHeader && "active"}`}>
                  <Link to={'/techservices'} onClick={() => handleOpenTechServicesNavBar()} className="serviceElement">
                    {t('TECH_SOLUTIONS')}
                  </Link>
                  <img className="imgHeaderservices" src={`${showTechServicesHeader ? "/img/arrowUpHeader.svg" : "/img/arrowDownHeader.svg"}`} alt="" onClick={() => handleOpenTechServicesNavBar()} />
                  <div className={`TechServicesNavbar ${showTechServicesHeader && "active"}`}>
                    {solutionsTech.map((solution, index) => (
                      <ul>
                        <li>
                          <p key={index}>
                            <Link to={solution.link}>
                              {solution.name}
                              {solution.name2}
                            </Link>
                          </p>
                        </li>
                      </ul>
                    ))}
                  </div>
                </p>
                {solutions.map((solution, index) => (
                  <p key={index} className="serviceElement">
                    <Link to={solution.link} className="serviceElement">
                      {solution.name}
                    </Link>
                  </p>
                ))}
              </div>
            </div>
            <button className="secondary-header-button"><Link to={'/about-us'}>{t('HOME_ABOUT_US')}</Link></button>
            <button className="secondary-header-button"><Link to={'/work-with-us'}>{t('GET_HIRED')}</Link></button>
            {/* <ButtonLinkComponent
              className={"header-button button"}
              url={'/work-with-us'}
              translateButton={'GET_HIRED'}
            /> */}
          </div>
          <LanguageSwitcher className="language-switcher" />
          <img className="menuHeaderPhone" src="/img/Menu_svg.svg" alt="" onClick={() => setShowMenu(!showMenu)} />
        </div>
      </div>
      <div className={`header-contact-container_phone ${showMenu && "active"}`} ref={elementoRef}>
        <button className="secondary-header-button"><Link to={'/work-with-us'}>{t('GET_HIRED')}</Link></button>
        <div className="servicesContainer">
          <div className="serviceTextContainer" onClick={() => handleOpenServicesNavBar()}>
            <button className={`secondary-header-button ${showServicesHeader && "activeWindow"} ${currentLocation === 'services' && 'activeWindow'}`}>{t('HEADER_SERVICES')}</button>
            <img className="imgHeaderservices" src={`${showServicesHeader ? "/img/arrowUpHeader.svg" : "/img/arrowDownHeader.svg"}`} alt="" />
          </div>
          <div className={`ServicesNavbar ${showServicesHeader && "active"}`}>
            <p className="techServicesContainer">
              <Link to={'/techservices'} onClick={() => handleOpenTechServicesNavBar()}>
                {t('TECH_SOLUTIONS')}
              </Link>
              <img className="imgHeaderservices" src={`${showTechServicesHeader ? "/img/arrowUpHeader.svg" : "/img/arrowDownHeader.svg"}`} alt="" onClick={() => handleOpenTechServicesNavBar()} />
              <div className={`TechServicesNavbar ${showTechServicesHeader && "active"}`}>
                {solutionsTech.map((solution, index) => (
                  <ul>
                    <li>
                      <p key={index}>
                        <Link to={solution.link}>
                          {solution.name}
                          {solution.name2}
                        </Link>
                      </p>
                    </li>
                  </ul>
                ))}
              </div>
            </p>
            {solutions.map((solution, index) => (
              <p key={index}>
                <Link to={solution.link}>
                  {solution.name}
                </Link>
              </p>
            ))}
          </div>
        </div>
        <button className="secondary-header-button"><Link to={'/about-us'}>{t('HOME_ABOUT_US')}</Link></button>
      </div>
    </>
  );

};

export default Header;
