
import Header from "../../../../components/header/header";
import Footer from "../../../../components/footer/footer";
import React, { useEffect, useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import "../../../../style/advantages.css";
import "../../../../style/contact-us.css";
import TextAreaComponent from "../../../../components/textAreaComponent";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import InputComponent from "../../../../components/inputComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FetchSuccessComponent from "../../../../components/fetchComponent/fetchComponent";
import optionsCountries from "../../../../utilities/countries_code";
import useDropdown from "../../../../hooks/searchDropdownHook";
import { filterByFieldOption, filterByCountryObject } from "../../../../utilities/filterfunctionsDropdowns";
import countries from "../../../../utilities/countries_states";

const Work_with_us = () => {
  const { t } = useTranslation();
  const [openDropdownCountry, setOpenDropdownCountry] = useState(false)
  const inputRef = useRef();
  const [skillsArray, setSkillsArray] = useState([])
  const [vacanciesData, setVacanciesData] = useState([])
  const [skillsData, setSkillsData] = useState([])
  const [filteredDepartments, setFilteredDepartments] = useState([])
  const initialData = {
    firstName: "",
    lastName: "",
    phoneNumberCode: "",
    phoneNumber: "",
    candidateEmail: "",
    currentCandidateDepartment: "",
    currentCandidateCity: "",
    remoteWork: true,
    skills: [],
    previousExperience: "",
    candidateResume: {},
    additionalInformation: "",
    RecruitingEffort: "",
    VacancyGUID: "",
    policies: false,
    ByForm: "Contractor"
  }
  const [loading, setLoading] = useState(false)
  const [errors, setErros] = useState({})
  const [form, setForm] = useState(initialData)


  const {
    isOpen: isCountryDropdownOpen,
    setIsOpen: setIsCountryDropdownOpen,
    searchTerm: searchTermCountry,
    filteredArray: filteredCountries,
    inputRef: countryDropdownRef,
    handleSearchChange: handleSearchChangeCountry,
    selectedItem: selectedCountry,
    setSelectedItem: setSelectedCountry
  } = useDropdown(countries.countries.map(country => country.name), filterByFieldOption);

  const {
    isOpen: isDepartmentDropdownOpen,
    setIsOpen: setIsDepartmentDropdownOpen,
    searchTerm: searchTermState,
    filteredArray: filteredState,
    inputRef: departmentDropdownRef,
    handleSearchChange: handleSearchChangeState,
    selectedItem: selectedState,
    setSelectedItem: setSelectedState
  } = useDropdown(filteredDepartments, filterByFieldOption);

  const {
    isOpen: openDropdownCountryCode,
    setIsOpen: setOpenDropdownCountryCode,
    searchTerm: searchTermCountryCode,
    filteredArray: filteredCountryCode,
    inputRef: elementoRef,
    handleSearchChange: handleSearchChangeCountryCode,
    selectedItem: selectedCountryCode,
    setSelectedItem: setSelectedCountryCode
  } = useDropdown(optionsCountries, filterByCountryObject);

  const handleDropdown = (set) => {
    set((prev) => !prev);
  }

  useEffect(() => {
    axios.get('https://prod-49.westus.logic.azure.com:443/workflows/2c614ab243244f2db1f10b462753208e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=thFXVLzYx-VUD85W9gp_jpFgOcHhwo8sFHxDR1M9Tds')
      .then((response) => {
        setVacanciesData(response.data);

      })
      .catch((error) => {

      });
  }, []);

  useEffect(() => {
    axios.get('https://prod-49.westus.logic.azure.com:443/workflows/2c614ab243244f2db1f10b462753208e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=thFXVLzYx-VUD85W9gp_jpFgOcHhwo8sFHxDR1M9Tds')
      .then((response) => {
        setSkillsData(response.data.vacancySkills);
      })
      .catch((error) => {

      });
  }, []);

  const handleChange = (event) => {
    const { name, value, checked } = event.target
    if (name === 'policies') {
      setForm({ ...form, [name]: checked })
    } else {
      setForm({ ...form, [name]: value })
    }

  }

  const handleSelectOption = (option, keyOption, set) => {
    if (keyOption == 'skills') {

      setSkillsArray([...skillsArray, option]);
      setForm({
        ...form,
        [keyOption]: [...skillsArray, option].join(",")
      });
    } else if (keyOption === "country") {
      setSelectedCountry(option);
      const country = countries.countries.find(country => country.name === option);
      setFilteredDepartments(country ? country.states : []);
      setForm({ ...form, [keyOption]: option })
    } else if (keyOption === "departmentState") {
      setSelectedState(option);
      setForm({ ...form, [keyOption]: option })
    } else if (keyOption === "phoneNumberCode") {
      setSelectedCountryCode(option);
      setForm({ ...form, [keyOption]: option })
    } else if (keyOption == 'vacancy') {
      setForm({ ...form, [keyOption]: option.vacancyName, ["RecruitingEffort"]: option.recruitingEffortId, ["VacancyGUID"]: option.vacancyGUID })
    } else {
      setForm({ ...form, [keyOption]: option })
    }
    set((prev) => !prev);
  }




  const onValidate = (form) => {
    let errors = {};

    if (!form.firstName.trim()) {
      errors.firstName = t('NAME_REQUIRED');
    }

    if (!form.lastName.trim()) {
      errors.lastName = t('LAST_NAME_REQUIRED');
    }

    if (!form.phoneNumberCode.trim()) {
      errors.phoneNumberCode = t('PHONE_CODE_REQUIRED');
    }

    if (!form.phoneNumber.trim()) {
      errors.phoneNumber = t('PHONE_NUMBER_REQUIRED');
    }

    if (!form.candidateEmail.trim()) {
      errors.candidateEmail = t('EMAIL_REQUIRED');
    }

    if (!form.currentCandidateDepartment.trim()) {
      errors.currentCandidateDepartment = t('CURRENT_CANDIDATE_DEPARTMENT_REQUIRED');
    }

    if (!form.currentCandidateCity.trim()) {
      errors.currentCandidateCity = t('CURRENT_CANDIDATE_CITY_REQUIRED');
    }

    if (!form.VacancyGUID.trim()) {
      errors.VacancyGUID = t('VACANCY_GUID_REQUIRED');
    }

    if (form.skills.length === 0) {
      errors.skills = t('SKILLS_REQUIRED');
    }

    if (!form.previousExperience.trim()) {
      errors.previousExperience = t('PREVIOUS_EXPERIENCE_REQUIRED');
    }

    if (!form.candidateResume || Object.keys(form.candidateResume).length === 0) {
      errors.candidateResume = t('CANDIDATE_RESUME_REQUIRED');
    }

    if (!form.additionalInformation.trim()) {
      errors.additionalInformation = t('ADDITIONAL_INFO_REQUIRED');
    }

    if (!form.policies) {
      errors.policies = t('POLICIES_REQUIRED');
    }

    return errors;
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
    const base64File = await convertToBase64(event.target.files[0]);
    setForm({
      ...form, ["candidateResume"]: {
        "content-type": event.target.files[0].type,
        content: base64File,
      }
    })
  };

  const onChooseFile = (e) => {
    e.preventDefault()
    inputRef.current.click();
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };



  useEffect(() => {
    // Función para manejar los clics en toda la página
    const handleClickOutside = (event) => {

      if (elementoRef.current && (elementoRef.current.classList.contains('inputNumber1') || elementoRef.current.classList.contains('inputNumber2'))) {
        // Si el clic ocurre fuera del elemento deseado y la clase no es 1 ni 2, cambiar el estado
        setOpenDropdownCountry(false);
      }

    };

    // Agregar event listener al montar el componente
    document.addEventListener('click', handleClickOutside);

    // Limpiar event listener al desmontar el componente
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); // Se ejecuta solo al montar y desmontar el componente

  const form2 = useRef();


  onValidate(form)

  const [isFetchSuccessful, setIsFetchSuccessful] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    const validationErrors = onValidate(form);
    setErros(validationErrors);

    if (!selectedFile) {
      setUploadStatus('Please select a file to upload.');
      return;
    }
    if (Object.keys(validationErrors).length === 0) {
      try {
        const base64File = await convertToBase64(selectedFile);
        const payload = {
          candidateResume: {
            "content-type": selectedFile.type,
            content: base64File,
          },
        };
        setForm({
          ...form, ["candidateResume"]: {
            "content-type": selectedFile.type,
            content: base64File,
          }
        })

        const convertSkillsToString = (data) => {
          return {
            ...data,
            skills: data.skills.join(', ')
          };
        };

        const response = await fetch('https://prod-157.westus.logic.azure.com:443/workflows/c5224c81f5fa471d9e4bf02bbd3c2f0a/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=wcq_EpvAkydHzfVvbtQ7uJ2NU6_3UjXPi49qY8Efawc', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(convertSkillsToString(form)),
        });

        if (response.ok) {
          setIsFetchSuccessful(true); // Cambia el estado a true si la respuesta es exitosa
        } else {
          setIsFetchSuccessful(false); // Cambia el estado a false si la respuesta no es exitosa
        }

      } catch (error) {
        setUploadStatus('Error uploading file.');
      }
    }
  };

  const notifySucces = () => toast.success('Form send correctly', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  });

  const notifyError = () => toast.error('Form does not send correctly', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  });

  const selectedVacancy = vacanciesData.find(vacancy => vacancy.vacancyGUID == form.VacancyGUID);
  const selectedSkills = selectedVacancy ? selectedVacancy.vacancySkills : [];


  const [openPositionDropdowns, setOpenPositionDropdowns] = useState({});
  const [positionDropdowns, setPositionDropdowns] = useState([{ id: 1, value: '' }]);
  const [openTimeDropdowns, setOpenTimeDropdowns] = useState({});
  const [timeDropdowns, setTimeDropdowns] = useState([{ id: 1, value: '' }]);
  const [skillsDropdowns, setSkillsDropdowns] = useState([{ id: 1, value: '' }]);
  const [dropdowns, setDropdowns] = useState([{ id: 1, value: '' }]);

  const handleSelectOptionPosition = (option, dropdownId) => {
    setPositionDropdowns((prev) =>
      prev.map((dropdown) =>
        dropdown.id === dropdownId ? { ...dropdown, value: option.vacancyName } : dropdown
      )
    );

    setOpenPositionDropdowns((prev) => ({ ...prev, [dropdownId]: false }));

    setForm((prev) => ({
      ...prev,
      VacancyGUID: [...prev.VacancyGUID, option],
    }));
    setForm({ ...form, ["RecruitingEffort"]: option.recruitingEffortId, ["VacancyGUID"]: option.vacancyGUID })
  };

  const addPositionDropdown = () => {
    setPositionDropdowns((prev) => [...prev, { id: prev.length + 1, value: '' }]);
  };

  const handleSelectOptionTime = (option, dropdownId) => {
    setTimeDropdowns((prev) =>
      prev.map((dropdown) =>
        dropdown.id === dropdownId ? { ...dropdown, value: option } : dropdown
      )
    );

    setOpenTimeDropdowns((prev) => ({ ...prev, [dropdownId]: false }));

    setForm((prev) => ({
      ...prev,
      skills: [...prev.skills, option],
    }));
  };

  const addTimeDropdown = () => {
    setTimeDropdowns((prev) => [...prev, { id: prev.length + 1, value: '' }]);
  };
  const addDropdown = () => {
    setDropdowns((prev) => [...prev, { id: prev.length + 1, value: '' }]);
  };

  const isAllOptionsSelected = vacanciesData.every((option) =>
    dropdowns.some((d) => d.value === option.Service_Name)
  );

  return (
    <>
      <Header></Header>
      <div className="workWithUsBanner"></div>
      <main className="workWithUsFormMainContainer">
        <div className="contact-us-forms">
          <form ref={form2}>
            <h2 className="form-title">{t('FORM_TITLE')}</h2>
            <div className="nameContainer">
              <div className="errorContainer">
                <InputComponent
                  className={`input-width`}
                  name={'firstName'}
                  value={form.firstName}
                  handleChange={handleChange}
                  label={t('FORM_FIRST_NAME')}
                />
                {errors.firstName && <div className="alert alert-danger">{errors.firstName}</div>}
              </div>
              <div className="errorContainer">
                <InputComponent
                  className={`input-width`}
                  name={'lastName'}
                  value={form.lastName}
                  handleChange={handleChange}
                  label={t('FORM_LAST_NAME')}
                />
                {errors.lastName && <div className="alert alert-danger">{errors.lastName}</div>}
              </div>
            </div>
            <div className="phoneEmailContainer">
              <div>
                <p>{t('FORM_PHONE_NUMBER')}</p>
                <div className="phoneContactContainer phoneCode">
                  <div className="phoneContactDropdown phoneCode" ref={elementoRef}>
                    <div className="phoneContactInputContainer">
                      <input
                        type="text"
                        className={`inputNumber ${openDropdownCountryCode && "active"}`}
                        onClick={() => handleDropdown(setOpenDropdownCountryCode)}
                        onChange={handleSearchChangeCountryCode}
                        name="phoneNumberCode"
                        value={selectedCountryCode ? selectedCountryCode : searchTermCountryCode}
                        autoComplete="off"
                      />
                      <img src={'/img/arrowDownDropdown.svg'} alt="" />
                    </div>
                    {openDropdownCountryCode && (
                      <div className="phoneContactOptions">
                        <div className="optionsContainer">
                          {filteredCountryCode.map((option) => (
                            <p onClick={() => handleSelectOption(option.code, "phoneNumberCode", setOpenDropdownCountryCode)} key={option.country}>
                              {option.country}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="errorContainer">
                    <InputComponent
                      className={`input-width`}
                      name={'phoneNumber'}
                      type={'number'}
                      handleChange={handleChange}
                      value={form.phoneNumber}
                    />
                  </div>
                </div>
                {errors.phoneNumber && <div className="alert alert-danger">{errors.phoneNumber}</div>}
                {errors.phoneNumberCode && <div className="alert alert-danger">{errors.phoneNumberCode}</div>}
              </div>
              <div className="errorContainer email">
                <InputComponent
                  className={`input-width`}
                  label={t('FORM_EMAIL')}
                  name={'candidateEmail'}
                  type={'email'}
                  value={form.candidateEmail}
                  handleChange={handleChange}
                />
                {errors.candidateEmail && <div className="alert alert-danger">{errors.candidateEmail}</div>}
              </div>
            </div>
            <div className="AddressRemoteContainer">
              <div className="phoneContactContainer">
                <div className="phoneContactDropdown" ref={countryDropdownRef}>
                  <p>{t('FORM_LABEL_COUNTRY')}</p>
                  <div className="phoneContactInputContainer">
                    <input
                      type="text"
                      className={`inputNumber1 ${isCountryDropdownOpen && "active"}`}
                      onClick={() => handleDropdown(setIsCountryDropdownOpen)}
                      onChange={handleSearchChangeCountry}
                      placeholder={t('WORK_US_YOUR_COUNTRY')}
                      name="country"
                      value={selectedCountry ? selectedCountry : searchTermCountry}
                      autoComplete="off"
                    />
                    <img src={'/img/arrowDownDropdown.svg'} alt="" />
                  </div>
                  {errors.country && <div className="alert alert-danger">{errors.country}</div>}
                  {isCountryDropdownOpen && (
                    <div className="phoneContactOptions">
                      <div className="optionsContainer">
                        {filteredCountries.map(option => (
                          <p
                            onClick={() => handleSelectOption(option, "country", setIsCountryDropdownOpen)}
                            key={option}
                          >
                            {option}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="phoneContactContainer">
                <div className="phoneContactDropdown" ref={departmentDropdownRef}>
                  <p>{t('FORM_LABEL_DEPARTMENT')}</p>
                  <div className="phoneContactInputContainer">
                    <input
                      type="text"
                      className={`inputNumber1 ${isDepartmentDropdownOpen && "active"}`}
                      onClick={() => handleDropdown(setIsDepartmentDropdownOpen)}
                      onChange={handleSearchChangeState}
                      placeholder={t('FORM_DEPARTMENT_LOCATION')}
                      name="departmentState"
                      value={selectedState ? selectedState : searchTermState}
                      autoComplete="off"
                    />
                    <img src={'/img/arrowDownDropdown.svg'} alt="" />
                  </div>
                  {errors.departmentState && <div className="alert alert-danger">{errors.departmentState}</div>}
                  {isDepartmentDropdownOpen && (
                    <div className="phoneContactOptions">
                      <div className="optionsContainer">
                        {filteredState.length > 0 ? (
                          filteredState.map((option) => {
                            return (
                              <p
                                onClick={() => handleSelectOption(option, "departmentState", setIsDepartmentDropdownOpen)}
                                key={option}
                              >
                                {option}
                              </p>
                            );
                          })
                        ) : null}

                        {(filteredState.length === 0) && (
                          <p style={{ color: 'grey' }}>
                            No state
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="errorContainer">
              <InputComponent
                className={`input-width`}
                name={'previousExperience'}
                value={form.previousExperience}
                label={t('FORM_LABEL_PREVIOUS_EXPERIENCE')}
                handleChange={handleChange}
              />
              {errors.previousExperience && <div className="alert alert-danger">{errors.previousExperience}</div>}
            </div>
            <p>{t('FORM_LABEL_SUBMIT_RESUME')}</p>
            <div className="uploadFileContainer">
              <input ref={inputRef} type="file" className="button" onChange={handleFileChange} style={{ display: "none" }} />
              <button className="button" onClick={onChooseFile}>
                {t('FORM_LABEL_CV')}
              </button>
              <p>{selectedFile?.name}</p>
            </div>
            {errors.candidateResume && <div className="alert alert-danger">{errors.candidateResume}</div>}
            <div className="phoneContactContainer services">
              <div className="dropdownsContainer">
                <p>{t('FORM_LABEL_JOB_POSITION')}</p>
                {positionDropdowns.map((dropdown) => (
                  <div key={dropdown.id} className="phoneContactDropdown" ref={elementoRef}>
                    <div className="phoneContactInputContainer">
                      <input
                        type="text"
                        className={`inputNumber2 ${dropdown.value && 'active'}`}
                        onClick={() =>
                          setOpenPositionDropdowns((prev) => ({
                            ...prev,
                            [dropdown.id]: !prev[dropdown.id],
                          }))
                        }
                        placeholder={t('WORK_US_VACANCY')}
                        name={`vacancy-${dropdown.id}`}
                        readOnly
                        disabled={!!dropdown.value}
                        value={dropdown.value}
                      />
                      <img src={'/img/arrowDownDropdown.svg'} alt="" />
                    </div>
                    {openPositionDropdowns[dropdown.id] && (
                      <div className="phoneContactOptions">
                        <div className="optionsContainer">
                          {/* {vacanciesData.map((option) => {
                            const isOptionSelected = positionDropdowns.some((d) => d.value === option.vacancyGUID);
                            if (!isOptionSelected) {
                              return (
                                <p
                                  onClick={() => handleSelectOptionPosition(option, dropdown.id)}
                                  key={option.vacancyName}
                                >
                                  {option.vacancyName}
                                </p>
                              );
                            }
                            return null;
                          })} */}
                          {/* {(vacanciesData.length === 0 || isAllOptionsSelected) && ( */}
                          {(true) && (
                            <p style={{ color: 'grey' }}>
                              No vacancies
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                {errors.VacancyGUID && <div className="alert alert-danger">{errors.VacancyGUID}</div>}
              </div>
              <div className="dropdownsContainer">
                <p>{t('FORM_LABEL_SKILLS')}</p>
                {timeDropdowns.map((dropdown) => (
                  <div key={dropdown.id} className="phoneContactDropdown">
                    <div className={`phoneContactInputContainer ${(timeDropdowns.length > 1 || timeDropdowns[timeDropdowns.length - 1].value) && "skills"}`}>
                      <input
                        type="text"
                        className={`inputNumber2 ${dropdown.value && 'active'}`}
                        onClick={() =>
                          setOpenTimeDropdowns((prev) => ({
                            ...prev,
                            [dropdown.id]: !prev[dropdown.id],
                          }))
                        }
                        placeholder="Select an option"
                        name={`timeNeedExpert-${dropdown.id}`}
                        readOnly
                        disabled={!!dropdown.value}
                        value={dropdown.value}
                      />
                      <img src={'/img/arrowDownDropdown.svg'} alt="" />
                      {(timeDropdowns.length > 1 || timeDropdowns[timeDropdowns.length - 1].value) && (
                        <p
                          className="editButton"
                          onClick={() =>
                            setTimeDropdowns((prev) =>
                              prev.map((d) =>
                                d.id === dropdown.id ? { ...d, value: '' } : d
                              )
                            )
                          }
                        >
                          {t("EDIT")}
                        </p>
                      )}
                    </div>
                    {openTimeDropdowns[dropdown.id] && (
                      <div className="phoneContactOptions">
                        <div className="optionsContainer">
                          {selectedSkills.map((option) => {
                            const isOptionSelected = skillsDropdowns.some((d) => d.value === option.technologyName);
                            if (!isOptionSelected) {
                              return (
                                <p
                                  onClick={() => handleSelectOptionTime(option.technologyName, dropdown.id, 'skills')}
                                  key={option.technologyName}
                                >
                                  {option.technologyName}
                                </p>
                              );
                            }
                            return null;
                          })}
                          {(selectedSkills.length === 0) && (
                            <p style={{ color: 'grey' }}>
                              No Skills
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {timeDropdowns[timeDropdowns.length - 1].value && (
                <button className="buttonAddDropdown" onClick={addTimeDropdown}>
                  + Add Another Skill
                </button>
              )}
            </div>
            {errors.skills && <div className="alert alert-danger">{errors.skills}</div>}

            <p>{t('WORK_US_RESUME_MESSAGE')}</p>
            <div className="errorContainer">
              <TextAreaComponent
                className={`input-width textArea`}
                name={'additionalInformation'}
                handleChange={handleChange}
                value={form.additionalInformation}
              />
              {errors.additionalInformation && <div className="alert alert-danger">{errors.additionalInformation}</div>}
            </div>
            <div className="termsAndConditionsContainer">
              <input type="checkbox" name="policies" onChange={handleChange} />
              <p className="termsAndConditionsText">{t('FORM_POLICY')} <a href="/PoliticaDeTratamientoDeDatosPersonales.pdf">{t('FORM_POLICY_LINK')}</a></p>
            </div>
            {errors.policies && <div className="alert alert-danger">{errors.policies}</div>}
            <button className="button" onClick={sendEmail}>{loading ? t('FORM_BUTTON_LOADING') : t('FORM_BUTTON')}</button>
            <FetchSuccessComponent isFetchSuccessful={isFetchSuccessful} setIsFetchSuccessful={setIsFetchSuccessful} url={'/contractors'} />
          </form>
        </div>
      </main>

      <Footer></Footer>
    </>
  );
};

export default Work_with_us;
