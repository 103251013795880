
import '../style/app.css';
import Header from "../components/header/header.jsx";
import Footer from "../components/footer/footer.jsx";
import Banner from "../pages/banner";
import Contact from "../components/contact_us";
import Services from "../components/services/main/services.jsx";
import call_Contact from "../components/access_contact.jsx";
import { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcaseMedical, faCode, faGlobe } from '@fortawesome/free-solid-svg-icons';
import Contact_us from '../components/contact_us';
import TargetComponent from '../components/targetsComponent/targetComponent.jsx';
import { useTranslation } from 'react-i18next';
import ButtonLinkComponent from '../components/buttonLink/buttonLink.jsx';
import BenefitsCardsComponent from '../components/benefitsCardsComponent/benefitsCardsComponent.jsx';
import ReactPlayer from 'react-player';

function App() {
  const { t } = useTranslation();

  const services = [
    {
      title: t('HOME_ECOMMERCE_B2B'),
      description: t('HOME_ECOMMERCE_B2B_DESC'),
      url: "/moreservices/ecommerce",
      img: "/ecommerce_Icon.svg"
    },
    {
      title: t('HOME_CRM'),
      description: t('HOME_CRM_DESC'),
      url: "/moreservices/crm",
      img: "/CRM_Icon.svg"
    },
    {
      title: t('HOME_ERP'),
      description: t('HOME_ERP_DESC'),
      url: "/moreservices/erp",
      img: "/ERP_Icon.svg"
    }
  ];

  const successStories = [
    {
      name: "AT&T",
      title: t('HOME_CLIENT_STORY_1_TITLE'),
      quote: t('HOME_CLIENT_STORY_1_DESC'),
      photo: "https://cdn.icon-icons.com/icons2/2699/PNG/512/att_logo_icon_170510.png"
    },
    {
      name: "Compusoft Integrated solutions inc.",
      title: t('HOME_CLIENT_STORY_3_TITLE'),
      quote: t('HOME_CLIENT_STORY_3_DESC'),
      photo: "https://www.compusoft-is.com/wp-content/uploads/2019/05/logo_final.jpg"
    },
    {
      name: t('HOME_CLIENT_ENVIGADO_NAME'),
      title: t('HOME_CLIENT_ENVIGADO_TITLE'),
      quote: t('HOME_CLIENT_ENVIGADO_DESC'),
      photo: "https://www.envigado.gov.co/sites/alcaldia-municipal-de-envigado-en-antioquia/content/files/002361/118032_escudo_200x200.png"
    }
  ];

  const partnerNetworks = [
    {
      name: "MicroHome",
      logo: "cropped-Logo-Microhome-05-1 1.jpg"
    },
    {
      name: "AT&T",
      logo: "AT&T_logo_2016.svg 1.jpg"
    },
    {
      name: "Compusoft",
      logo: "image 1.jpg"
    }
  ];

  const features = [
    {
      title: t("HOME_EMPLOYEE_BENEFITS"),
      text: t('HOME_EMPLOYEE_BENEFITS_DESC'),
      icon: "unbrellaIcon.svg"
    },
    {
      title: t('HOME_PROJECT_TRACKING'),
      text: t('HOME_PROJECT_TRACKING_DESC'),
      icon: "trackingIcon.svg"
    },
    {
      title: t('HOME_CLIENT_INFORMATION'),
      text: t('HOME_CLIENT_INFORMATION_DESC'),
      icon: "clientInfoIcon.svg"
    }
  ];

  const params = useParams();
  useEffect(() => {
    if (params.toService === "to-service") {
      setTimeout(() => {
        const component = document.querySelector(".services-container");
        if (component) {
          component.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else if (params.toService === "to-contact") {
      setTimeout(() => {
        const component = document.querySelector(".contact-us-container");
        if (component) {
          component.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, [params]);

  // Component for services cards
  function CardServices({ img, imgFontAwesome, title, subtitle, text, route, className, mainImg }) {
    return (
      <div className={`cardServiceContainer ${className}`}>
        <div className='cardMainContainer'>
          <div className="imgMainContainer">
            {mainImg && <img className="mainImg" src={"/img/" + mainImg} alt="" />}
          </div>
          <div className='cardTitleContainer'>
            <div className='imgCardService'>
              {imgFontAwesome && <FontAwesomeIcon icon={imgFontAwesome} />}
              {img && <img src={"/img/" + img} alt="" />}
            </div>
            <h2 className='h2'>{title}</h2>
            <p className='pCardServiceTitle'>{subtitle ? subtitle : ""}</p>
          </div>
          <p className='mainTextCardService'>{text}</p>
        </div>
        <ButtonLinkComponent
          translateButton={'HOME_BUTTON_INFO'}
          url={route}
          blank={false}
        />
      </div>
    )
  }

  return (
    <div className="App">
      <Header handleRedirect2={call_Contact} />
      <main className="mainContainerHome">
        <section className="welcomeMessageSection">
          <h1 className="title">{t('HOME_WELCOME_TITLE')}</h1>
          <ButtonLinkComponent
            translateButton={'BOOK_APPOINTMENT_BUTTON'}
            url={"https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/"}
            blank={true}
          />
        </section>
        <section className="servicesSection">
          <h1 className='title'>{t('OUR_SERVICES')}</h1>
          <div className="ServicesContainer">
            <CardServices
              imgFontAwesome={faCode}
              title={t('TECH_SERVICES_TITLE')}
              text={t('TECH_SERVICES_TEXT')}
              route={'/techservices'}
              mainImg={'DreamShaper_v7_artificial_intelligence_and_machine_learning_2.jpg'}
              className={'tech'}
            />
            <CardServices
              imgFontAwesome={faBriefcaseMedical}
              title={t('VMA_TITLE_HOME')}
              text={t('VMA_TEXT_HOME')}
              subtitle={t('VMA_SUBTITLE')}
              mainImg={'openart-image_WJoUryst_1716397116164_raw.jpg'}
              route={'/vma'}
            />
            <CardServices
              img={'recruitment.svg'}
              title={t('CONSULTING_TITLE')}//bad change with consulting/ easy quick fixx applied / need to change dictionary
              text={t('CONSULTING_TEXT')}
              mainImg={'businessman-working-futuristic-office.jpg'}
              route={'/recruitment'}
            />
            <CardServices
              imgFontAwesome={faGlobe}
              
              title={t('RECRUITMENT_TITLE_CARD')}
              text={t('RECRUITMENT_TEXT')}
              mainImg={'medium-shot-woman-working-as-lawyer.jpg'}
              route={'/contractors'}
            />
            <CardServices
              img={'investments.svg'}
              title={t('INVESTMENTS_TITLE')}
              text={t('INVESTMENTS_TEXT')}
              mainImg={'hombre-tiro-monedas (1).jpg'}
              route={'/investment'}
            />
          </div>
        </section>
        <section className="people360">
          <h2 className="titleH2">{t('HOME_INTRODUCING_PEOPLE_360')}</h2>
          <p className="textBlack">{t('HOME_ONE_STOP_SOLUTION')}</p>
          <p className="mediumText">{t('HOME_PEOPLE_360_DESC')}</p>
          <BenefitsCardsComponent
            cardsData={features}
          />
          <div className="videoContainer">
            <ReactPlayer
              url={'/img/Vdieo people 360 home.mp4'}
              className='VideoPlayer'
              controls
            />
          </div>
        </section>
        <section className="moreServices servicesSection">
          <h2 className="titleH2">{t('HOME_SERVICES_TITLE')}</h2>
          <div className="bannerMoreServices"></div>
          <div className='ServicesContainer'>
            {services.map((service, index) => (
              <CardServices
                img={service.img}
                title={t(service.title)}
                text={t(service.description)}
                route={service.url}
              />
            ))}
          </div>
        </section>
        <section className="clientStories">
          <h2 className="titleH2">{t('HOME_CLIENT_SUCCESS_STORIES')}</h2>
          <div className='clientsMainContainer'>
            {successStories.map((story, index) => (
              <div key={index} className='clientContainer'>
                <div className="clientInfo">
                  <div className="clientImgContainer">
                    <img src={`${story.photo}`} alt={`${story.name}'s photo`} />
                  </div>
                  <div className="clientNameContainer">
                    <h3>{story.name}</h3>
                    <h4>{story.title}</h4>
                  </div>
                </div>
                <p>{story.quote}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="partner">
          <h2 className="titleH2">{t('HOME_PARTNERS')}</h2>
          <div className='partnersContainer'>
            {partnerNetworks.map((partner, index) => (
              <img src={`/img/${partner.logo}`} alt={`${partner.name} logo`} />
            ))}
          </div>
        </section>
        <section className='roofSection'>
          <h2>{t('ALL_UNDER_ONE_ROOF_TITLE')}</h2>
          <div className="roofsContainer">
            <TargetComponent
              img={'/img/diverse-group-smiles-teamwork-leads-success-generated-by-ai 1.jpg '}
              text={t('HOME_DRIVING_FUTURE_DESC_1')}
            />
            <TargetComponent
              img={'/img/cinematic-person-is-looking-customer-journey-map 1.jpg'}
              text={t('HOME_DRIVING_FUTURE_DESC_2')}
            />
            <TargetComponent
              img={'/img/person-looking-futuristic-mystical-other-worldly-sky 1.jpg'}
              text={t('HOME_DRIVING_FUTURE_DESC_3')}
            />
            <TargetComponent
              img={'/img/glowing-filament-ignites-inspiration-bright-ideas-indoors-generated-by-ai 1.jpg'}
              text={t('HOME_DRIVING_FUTURE_DESC_4')}
            />
            <TargetComponent
              img={'/img/person-working-futuristic-office 1.jpg'}
              text={t('HOME_DRIVING_FUTURE_DESC_5')}
            />
          </div>
          <ButtonLinkComponent
            translateButton={'BOOK_APPOINTMENT_BUTTON'}
            url={"https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/"}
            blank={true}
          />
        </section>
      </main>
      <Footer />
    </div>
  );
}


export default App;